<template>
  <div :class="{'form-inline d-flex': isInline, 'mb-3': hasBottomMargin}">
    <label
        v-if="!hasInputDesign || title"
        :class="{
          'col-form-label-sm': size.toString() === 'sm_test',
          'col-form-label-lg': size.toString() === 'lg_test',
          'd-inline-block': isInline,
          'd-block w-100': !isInline,
          'mb-2 mr-3': isFilter
        }"
        :for="name"
        class="font-size-lg mb-0"
        style="width: auto !important;"
    >
      {{ title }}
      <span v-if="required" class="text-danger">*</span>
      <info-tooltip
          v-if="helperText"
          :helper-text="helperText"
      ></info-tooltip>
    </label>

    <div :class="{'mx-3': !isOpenMultiselect && isInline, 'w-100': isOpenMultiselect}" class="relative">
      <div v-if="!isOpenMultiselect && !hasInputDesign"
           :class="{
            'form-control-sm': size.toString() === 'sm',
            'form-control-lg': size.toString() === 'lg',
            'disabled': disabled,
            'is-valid': notList ? modelProxy.length !== 0 : (modelProxy.length >= min && modelProxy.length <= max && modelProxy.length !== 0),
            'is-invalid': showInvalid && !(notList ? modelProxy.length !== 0 : (modelProxy.length >= min && modelProxy.length <= max && modelProxy.length !== 0))
           }"
           :style="{maxWidth: inputMaxWidth + ' !important', width: inputWidth + ' !important', 'overflow': 'hidden'}"
           class="form-control rounded-full d-inline-block"
           @click="openOptions"
      >
        <input :value="modelProxy" class="d-none" type="text" @focus="openOptions">
        <span class="d-inline-block" style="line-height: 100%; text-overflow: ellipsis;">
          <span v-for="(value, index) in modelProxy.length"
                v-if="maxShow >= modelProxy.length && modelProxy.length > 0">
            {{
              Array.isArray(options) || objectKey === null ? options[modelProxy[index]] : getValueByDottedKey(options[modelProxy[index]], objectKey)
            }}{{ (index !== (modelProxy.length - 1)) && modelProxy.length !== 1 ? ', ' : '' }}
          </span>
          <span v-if="maxShow < modelProxy.length">
              {{ `${modelProxy.length}` }} <span
              class="d-xl-inline-block d-lg-inline-block d-md-none d-sm-none">{{ $t('general.items_selected') }}</span>
          </span>
          <span v-if="modelProxy.length === 0">
            {{ $t(placeholder) }}
          </span>
        </span>
      </div>
      <div v-if="hasInputDesign && !isOpenMultiselect" class="p-0 m-0" @click="openOptions">
        <slot name="inputDesign"></slot>
      </div>
      <div
          v-if="isOpenMultiselect && !disabled"
          :id="name"
          :class="{'right-0': isRight, 'bottom-0': isTop}"
          :style="{width: width + ' !important', }"
          class="navy-blue-border-2px border-15px row m-0 p-2 justify-content-center z-index-1000 bg-white shadow-sm absolute">
        <div class="col-3"></div>
        <div class="col-3"></div>
        <div class="col-3"></div>
        <div class="col-12 mb-3 px-0 w-100">
          <input v-model="searchValue" :disabled="disabled"
                 :placeholder="$t('general.please_enter_key_to_search')"
                 class="w-100 form-control form-control-xs" type="text"
          >
        </div>
        <div v-if="errorMessage" class="col-12 text-center text-danger mb-2">
          {{ errorMessage }}
        </div>
        <div :style="{maxHeight: height + ' !important'}"
             class="col-12 p-0 m-0 row justify-content-center items-baseline items-start"
             style="overflow-y: auto;">
          <div v-for="(value, index) in optionsProxy" :key="index" :class="[`col-${(12/itemPerRow).toFixed(0)}`]"
               class="px-1 py-1">
            <div :class="{'active-item': tempSelectedItems.includes(Array.isArray(modelProxy) ? index : index.toString()) }"
                 class="w-100 multiselect-item text-center"
                 @click="onSelectItem(Array.isArray(optionsProxy) ? Number(index) : index.toString() )">
              {{
                Array.isArray(options) ? value : objectKey ?
                    getValueByDottedKey(value, objectKey) :
                    typeof value === 'object' ? JSON.stringify(value) : value.toString()
              }}
            </div>
          </div>
        </div>
        <div v-if="Array.isArray(optionsProxy) ? optionsProxy.length === 0 : Object.keys(optionsProxy).length === 0"
             class="col-12 text-center font-weight-bolder">
          {{ $t('general.no_records_found_filter') }}
        </div>
        <div class="col-12 d-flex justify-content-center mt-3">
          <button class="btn btn-outline-primary btn-sm btn-pill py-1" type="button" @click="closeOptions">
            {{ $t('general.cancel').toUpperCase() }}
          </button>
          <button class="btn btn-outline-primary btn-sm btn-pill py-1 ml-2" type="button" @click="saveChanges">
            {{ $t('general.save').toUpperCase() }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfoTooltip from "@/assets/components/widget/InfoTooltip";

export default {
  name: "CustomMultiSelect",
  props: {
    isInline: {
      require: false,
      default: true,
    },
    isFilter: {
      required: false,
      default: false,
    },
    size: {
      default: 'sm',
      validator: function validator(l) {
        return ['xs', 'sm', 'md', 'lg', 'xl'].indexOf(l) > -1;
      }
    },
    name: {
      required: true,
    },
    title: {
      required: true,
    },
    required: {
      required: false,
      default: true,
    },
    helperText: {
      required: false,
      default: null,
    },
    placeholder: {
      required: false,
      default: 'general.please_select_options'
    },
    itemPerRow: {
      required: false,
      default: 1,
      type: Number,
      validator: function validator(value) {
        return value >= 1 && value <= 12;
      }
    },
    options: {
      required: true,
      type: Object | Array
    },
    searchable: {
      required: false,
      default: false,
    },
    objectKey: {
      required: false,
      default: null,
    },
    model: {
      required: true,
    },
    min: {
      required: false,
      default: 0,
    },
    hasBottomMargin: {
      required: false,
      default: true,
    },
    max: {
      required: false,
      default: Infinity,
    },
    disabled: {
      required: false,
      default: false,
    },
    height: {
      required: false,
      default: '250px',
    },
    width: {
      required: false,
      default: '200px'
    },
    widthClass: {
      required: false,
      default: 'w-100'
    },
    inputMaxWidth: {
      required: false,
      default: '250px'
    },
    inputWidth: {
      required: false,
      default: 'auto',
    },
    maxShow: {
      default: 4,
      required: false,
      type: Number,
    },
    notList: {
      required: false,
      default: false,
    },
    isRight: {
      required: false,
      default: false,
    },
    isTop: {
      required: false,
      default: false,
    },
    showInvalid: {
      required: false,
      default: true,
    }
  },
  components: {
    InfoTooltip,
  },
  data() {
    return {
      isOpenMultiselect: false,
      checkedInEvent: false,
      searchValue: null,
      tempSelectedItems: [],
      errorMessage: null,
    }
  },
  computed: {
    modelProxy: {
      get() {
        try {
          if (this.model === undefined) return [];
          if (!Array.isArray(this.model) && this.notList) {
            if (this.model === null || this.model === 0 || this.model === 'null') {
              return [];
            }
            return [this.model.toString()];
          }
          return this.model;
        } catch (e) {
          console.warn('Name : ' + this.name + this.model);
          console.error(e);
          return [];
        }
      },
      set(value) {
        if (Array.isArray(value) && this.notList) {
          if (value.length === 0) {
            this.$emit('update:model', null);
            this.$emit('onChange', null);
            return;
          }
          this.$emit('update:model', value[0]);
          this.$emit('onChange', value[0]);

          return;
        }
        this.$emit('update:model', value)
        this.$emit('onChange', value);
      }
    },
    optionsProxy: {
      get() {
        if (this.searchValue == null || this.searchValue.length === 0) return this.options;
        let returnResult = null;
        let searchValue = this.turkishToLower(this.searchValue.toString());
        if (Array.isArray(this.options)) {
          returnResult = [];
          for (let i = 0; i < this.options.length; i++) {
            if (this.options[i].toString().toLowerCase().startsWith(searchValue)) {
              returnResult.push(this.options[i]);
            }
          }
        } else {
          try {
            returnResult = {};
            for (let key in this.options) {
              if (this.objectKey && this.turkishToLower(this.getValueByDottedKey(this.options[key], this.objectKey).toString()).startsWith(searchValue)) {
                returnResult[key] = this.options[key];
              } else if (!this.objectKey && this.turkishToLower(this.options[key].toString()).includes(this.turkishToLower(searchValue))) {
                returnResult[key] = this.options[key];
              }
            }
          } catch (e) {
            console.error(e);
            return {};
          }
        }
        return returnResult;
      }
    },
    hasInputDesign() {
      return !!this.$slots.inputDesign
    },
  },
  mounted() {
    window.addEventListener('click', this.onClickOutsideOfSelector);
  },
  methods: {
    onClickOutsideOfSelector(e) {
      let temp = document.getElementById(this.name);
      if (temp != null) {
        if (temp.contains(e.target)) {
          // Clicked in box
        } else {
          if (this.isOpenMultiselect && this.checkedInEvent) {
            this.saveChanges();
            this.closeOptions();
          }

          this.checkedInEvent = true;
        }
      }
    },
    openOptions() {
      if (this.disabled) return;
      this.errorMessage = null;
      this.$emit('open');
      this.isOpenMultiselect = true;
      this.checkedInEvent = false;
      this.tempSelectedItems = [...this.modelProxy];
    },
    saveChanges() {
      this.errorMessage = null;
      if (this.tempSelectedItems.length < this.min) {
        this.errorMessage = `${this.$t('general.please_select_required_amount')}(${this.min})`
        return;
      }
      this.isOpenMultiselect = false;
      this.$emit('save', {
        name: this.name,
        oldValue: this.modelProxy,
        newValue: this.tempSelectedItems,
      });
      this.modelProxy = this.tempSelectedItems;
      this.tempSelectedItems = [];
    },
    closeOptions() {
      this.errorMessage = null;
      this.isOpenMultiselect = false;
      this.$emit('close');
    },
    onSelectItem(key) {
      this.errorMessage = null;
      let index = this.tempSelectedItems.indexOf(key)
      if (index > -1) {
        this.tempSelectedItems.splice(index, 1);
      } else {
        if (this.tempSelectedItems.length >= this.max) {
          this.tempSelectedItems.splice(0, 1);
          // this.errorMessage = `${this.$t('general.cant_select_more_than_selectable')}(${this.max})`
          // return;
        }
        this.tempSelectedItems.push(key);
        if (+this.max === 1 && this.tempSelectedItems.length === 1 ){
          this.saveChanges();
        }
      }
      this.$emit('onSelect', key, this.tempSelectedItems);
    },
  },
  destroyed() {
    document.removeEventListener('click', this.onClickOutsideOfSelector)
  }

}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/components/_variables.demo.scss';

//TODO: need to retrieve color value from scss file
.multiselect-item {
  border-radius: 10px;
  cursor: pointer;
  color: $navy-blue;
  font-weight: bolder;
  border: 1px solid #212c40;
}

.active-item {
  background-color: $primary !important;
  color: #fff;
  border: none;
}

.disabled {
  background-color: #F3F6F9;
  opacity: 1;
}
</style>
